import {React} from 'react'
import Content from '../layout/Content'
const AccessDenied = ({homeUrl}) => {
  return (
    <Content title='UCLA - Access Denied' pageTitle='ACCESS DENIED'>
        <div id="page_header_section">
            <h5 className="page_title_text">
                If you continue getting this page, please <a href="https://stage4.my.ucla.edu/feedback.aspx" title="Contact Us">Contact Us</a>.
            </h5>
            <div className="error_section">
                <h4 className="message error light text-center">You are not
                    authorized to access this page</h4>
                <div className="error_text_link">
                    <ul>
                        <li><a href={homeUrl} title="Your Home Page">Your Home Page</a></li>
                    </ul>
                </div>
                <ol>
                    <li style= {{ listStyle: "outside none none", paddingBottom: "1em" }}>
                        <h4>Why am I getting this page?</h4>
                            <p>This feature may no longer be available to you. You got here most likely by bookmarking this page and bypassing your home landing page.</p>
                    </li>
                
                    <li style={{ listStyle: "outside none none", paddingBottom: "1em" }}>
                        <h4>What can I do to avoid this page?</h4>
                        <ul>
                            <li>Remove any bookmarks to this page and login from <a href="http://my.ucla.edu">MyUCLA</a>.</li>
                            <li>Review your <a href="http://my.ucla.edu/directLink.aspx?featureID=298 ">MyUCLA Features</a>.  This shows all the features you have access to and it allows you to define your <i className="icon-home"></i> home feature.</li>
                        </ul>
                    </li>
                    <li style={{ listStyle: "outside none none",paddingBottom:"1em" }}>
                        <h4>I just signed up for Summer Sessions.  How do I provide/verify my official e-mail address?</h4>
                        <ul>
                            <li>Logout completely, close your browser, and login from <a href="http://my.ucla.edu">MyUCLA</a>.</li> 
                            <li>Go to <a href="https://studentweb.stage.it.ucla.edu/StudentWebMVC/app/personal">Official Email/Address/Phone feature under Settings</a>.</li>	
                        </ul>
                    </li>
                </ol>
            </div>
        </div> 
    </Content>
  )
}

export default AccessDenied