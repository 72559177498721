import React, { useState, useEffect } from 'react';
import AppRouter from './components/AppRouter'
import Header from './components/layout/Header'
import {AuthContext, decodeToken} from "./components/context/Auth"
import '@progress/kendo-theme-default/dist/all.css';
import axios from 'axios';
import { Loader } from "@progress/kendo-react-indicators";
function App() {
  let existingUser = localStorage.getItem("user");
  if(existingUser){
    existingUser=JSON.parse(existingUser)
  }
  const [user, setUser] = useState(existingUser)
  const [tokenValid, setTokenValid] = useState(null)
  const [menu, setMenu] = useState([])
  const [notices, setNotices] = useState(0)
  const [homeUrl, setHomeUrl] = useState('')
  const setAuthUser = (data)=>{
    let userString = data.user
    if(data && data.user){
      userString = JSON.stringify(data.user)
    }
    localStorage.setItem("user", userString)
    setUser(JSON.parse(userString))
    if((window.location.href.indexOf('/logout')<0) && data.token){
      // authzCheck(data.token);
      loadMenu(data.token);
    }
  }

  const [menuLoaded, setMenuLoaded] = useState(false)
  const [authzChecked, setauthzChecked] = useState(false)
  function loadMenu(id_token){
    if(!menuLoaded){
      let config = {
        method: 'post',
        url: process.env.REACT_APP_MYUCLA_MENU_HOST_URL + '/v1/wsregistrymyuclamenu',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+id_token
        }
      };
      axios(config)
      .then(function (response) {
        if(!menuLoaded && response && response.data && response.data.success){
          setMenuLoaded(true);
          setMenu(response.data.resData.data.menuData.superGroups);
          if(response.data.resData.data.menuData._homeURL){
            setHomeUrl(response.data.resData.data.menuData._homeURL);
          }
          if(response.data.resData.data.menuData.notices){
            setNotices(response.data.resData.data.menuData.notices);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        setTokenValid(false);
      });
    }
  }

  function authzCheck(id_token){
    if(!authzChecked){
      let config = {
        method: 'post',
        url: process.env.REACT_APP_DT_AUTHZ_HOST_URL + '/v1/authz',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+id_token
        }
      };
      axios(config)
      .then(function (response) {
        if(!authzChecked && response && response.data && response.data.success){
          console.log("ACCESS GRANTED!")
          setauthzChecked(true);
        }
        if(response && response.data && !response.data.success){
          console.log("REDIRECTING TO ACCESS DENIED!")
          // window.location.href = process.env.REACT_APP_ACCESS_DENIED_URL
        }
      })
      .catch(function (error) {
        console.log(error);
        // redirect to the access denied page
        console.log("REDIRECTING TO ACCESS DENIED! (ERROR)")
        // window.location.href = process.env.REACT_APP_ACCESS_DENIED_URL
      });
    }
  }

  useEffect(() => {
    if (window.location.href.indexOf('/logout')<0){
      const locationArray = window.location.href.split('#');
      let token = null;
      let hash = null
      if(locationArray.length>1){
        hash = locationArray[1];
      }
      if(hash && hash.split('&')[0].includes('id_token')){
        token = hash.split('&')[0].replace('#id_token=', '');
      }else if(hash && hash.split('&')[1] && hash.split('&')[1].includes('id_token')){
        token = hash.split('&')[1].replace('id_token=', '');
      } 
      if(token && !menuLoaded){
        let decodedToken = decodeToken(token);
        if(decodedToken){
          decodedToken.origToken = token;
        }
        if (decodedToken) {
          setAuthUser({'user': decodedToken, 'token': token})
        } else if(!decodedToken) {
          setAuthUser(null)
          setTokenValid(false)
        }
      }
      else if(user && user.origToken && tokenValid!=false){
        setAuthUser({'user': user, 'token': user.origToken})
      }
      else{
        setTokenValid(false)
      }
    
    }
  }, []);
  if(tokenValid==false){
    window.location.href= process.env.REACT_APP_COGNITO_SHIB_LOGIN_URL+"&redirect_uri="+window.location.href;
    return <Loader size="large" type="converging-spinner" className='center' />;;
  }
  if(!menu.length && (window.location.href.indexOf('/logout')<0)){
    return <Loader size="large" type="converging-spinner" className='center' />;
  }
  return (
    <AuthContext.Provider value ={{user, setTokenValid: setTokenValid}}>
      <layout-pagewrap unresolved>
        <Header menuData={menu} homeUrl={homeUrl} notices={notices}/>
        <AppRouter homeUrl={homeUrl} />
      </layout-pagewrap>
      <layout-footer unresolved>
        <link rel="import" href={process.env.REACT_APP_IWE_HOST_URL+"/iwe-static/vulc-min.html"} />
      </layout-footer>
    </AuthContext.Provider>
  );
}

export default App;