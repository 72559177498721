import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AccessDenied from './misc/AccessDenied'
import Logout from './misc/Logout'
import AuthRoute from './AuthRoute'
import AppRoute from './AppRoute'
function AppRouter(props) {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<AccessDenied homeUrl={props.homeUrl} />}/>
        <Route exact path='/logout' element={<Logout/>}/>
      </Routes>
    </Router>
  )
}

export default AppRouter